<script lang="jsx">
import { defineComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import Lite from './Lite.vue'
import EnhanceEntry from './EnhanceEntry.vue'
import { commonProps } from './product-props.js'

export default defineComponent({
  name: 'ProductItemSimpleEnhance',
  components: {
    Lite,
    EnhanceEntry
  },
  inheritAttrs: true,
  props: {
    ...commonProps,
  },
  render({ $props: props, $slots: slots, $attrs: listeners }) {
    const enhanceCtx = {
      ...props,
      ...listeners,
    }

    // 未启用 abt 或者客户端渲染时，直接渲染 Enhance
    if (this.context?.abtInfoObj?.ProductCardSwitch !== '1' || this.context?.clientRender) {
      return <EnhanceEntry {...enhanceCtx} />
    }

    const isSSR = typeof window === 'undefined'
    return (
      <div class="product-item-simple-enhance-container">
        <Lite v-show={isSSR} { ...props } />

        <ClientOnly>
          <EnhanceEntry
            class="not-fsp-element"
            {...enhanceCtx}
            v-slots={slots}
          />
        </ClientOnly>
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item-simple-enhance-container {
  width: 100%;
}
</style>
