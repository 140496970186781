export const commonProps = {
  lcp: {
    // 是否懒加载
    type: Boolean,
    default: false,
  },
  showViewMore: {
    // 是否显示查看更多
    type: Boolean,
    default: false,
  },
  viewMoreText: {
    //  查看更多文案
    type: String,
    default: '',
  },
  context: {
    // 上下文
    type: Object,
    default: () => {},
  },
  index: {
    // 第几个商品索引
    type: Number,
    default: 0,
  },
  item: {
    // 商品信息
    type: Object,
    default: () => {},
  },
  config: {
    // 配置信息
    type: Object,
    default: () => {},
  },
  column: {
    // 一行几列
    type: [String, Number],
    default: '',
  },
  constantData: {
    // 常量数据
    type: Object,
    default: () => {},
  },
  language: {
    // 多语言
    type: Object,
    default: () => {},
  },
  brand: {
    // 品牌
    type: String,
    default: 'shein',
  },
  cssRight: {
    // 是否镜像
    // GB_cssRight存在不准确的情况，所以传入cssRight兼容
    type: Boolean,
    default: false,
  },
  compSrc: {
    // 来源组件
    type: String,
    default: '',
  },
  cropRate: {
    // 裁切比例
    type: String,
    default: '3-4',
  },
  suggestedSaleType: {
    // 当前合规类型
    type: String,
    default: '',
  },
  priceFontSize: {
    // 驼峰字体大小
    type: Object,
    default: () => {},
  },
  compImgDesignWidth: {
    // 用于控制首页组件的裁切
    required: true,
    type: Number,
    default: 192,
  },
}
