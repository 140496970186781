<script lang="jsx">
import { defineComponent } from 'vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import HomeBffPrice from './HomeBffPrice.vue'
import { commonProps } from './product-props.js'

export default defineComponent({
  name: 'ProductItemLite',
  components: { CropImageContainer, HomeBffPrice },
  inheritAttrs: true,
  props: {
    ...commonProps,
  },
  render({ $props: props, $data: data }) {
    const {
      item,
      lcp,
      cropRate,
      compSrc,
      constantData,
      compImgDesignWidth,
      config,
      suggestedSaleType,
      priceFontSize,
    } = props

    const className = []
    if (lcp) {
      className.push('product-card--bg-gray')
    }

    return (
      <div
        class='product-item-simple__lite product-bff'
        {...data}
      >
        <CropImageContainer
          class={className}
          ignoreMask
          imgSrc={item.goods_img}
          fixedRatio={cropRate}
          transformImgProps={{
            imgClassName: `fsp-element product-card__main-img ${compSrc ? 'fsp__' + compSrc : ''}`,
            loadImg: constantData?.LAZY_IMG_SOLID_COLOR,
            isFirstPage: lcp,
            imgDesignWidth: compImgDesignWidth,
            alt: item.goods_name,
            fetchpriority: 'high',
          }}
        />
        <div class="product-card__bottom-wrapper">
          {config.hideAllBottomPrices ? null : (
            <HomeBffPrice
              goodsInfo={item}
              config={{ ...config, camelPriceResizeFontsize: false }}
              suggestedSaleType={suggestedSaleType}
              priceFontSize={priceFontSize}
            />
          )}
        </div>
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item-simple__lite {
  width: 100%;
  background-color: transparent; // 背景透明，避免白色造成四角未到圆角
  position: relative;
  .product-card__bottom-wrapper {
    padding: 0 0 2px;
  }
  .crop-image-container .product-card__main-img {
    border-radius: 0.055rem;
  }
  .prices-info__sale-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.product-card--bg-gray {
  background-color: @sui_color_gray_weak1a;
}
</style>
