<template>
  <div
    ref="productCardRef"
    class="product-card simple-row-item product-bff"
    :class="{
      'product-bff__view_more': showViewMore,
      'product-bff__border_out':
        item.homeBorder?.image?.src || item.maskLayer?.image,
    }"
    :style="{
      '--column-num': columnToWidthHome[column]
        ? `${columnToWidthHome[column]}rem`
        : '',
      '--product-card-radius': 0,
    }"
  >
    <div
      class="product-card__top-wrapper"
      @contextmenu_prevent="() => {}"
      @click="e => clickItem(e, { from: 'mainImg', emitsFun: 'clickItem' })"
    >
      <!-- 主图区域 -->
      <div class="top-wrapper__main-img">
        <!-- 主图区域 -->
        <ProductCardImg
          :main-img="mainImg"
          :column="column"
          :img-load-finish="onImgLoadFinish"
          :index="index"
          :auto-crop-image="config.autoCropImage"
          :cropRate="cropRate"
          :lcp="lcp"
          :report-metrics="metricsConfig?.reportMetrics"
          :comp-src="compSrc"
          :comp-img-design-width="compImgDesignWidth"
        />
      </div>
      <!-- 主图左上区域 -->
      <div class="top-wrapper__top-left">
        <ClientOnly>
          <div
            v-if="item.maskLayer?.image"
            class="product-bff__border"
            :style="borderStyle(webpCheck(item.maskLayer?.image))"
          ></div>
          <div
            v-if="item.homeBorder?.image?.src"
            class="product-bff__border"
            :style="borderStyle(webpCheck(item.homeBorder?.image?.src))"
          ></div>
          <!-- 商品角标 -->
          <div
            v-if="item.homeBadge?.text && !config.hideHomeBadge"
            class="product-bff__badge"
          >
            <div
              class="product-bff__badgetext"
              :style="badgeTextStyle(item.homeBadge)"
            >
              <BaseImg
                v-if="item.homeBadge?.icon?.src"
                fit="cover"
                :placeholder="{
                  width: item.homeBadge?.icon?.width,
                  height: item.homeBadge?.icon?.height,
                }"
                :img-src="webpCheck(item.homeBadge?.icon?.src)"
                class="not-fsp-element"
                :class="{
                  'product-bff__badgeicon_rotate': item.homeBadge?.icon?.isArReverseIcon,
                }"
                :img-design-width="Number(item.homeBadge?.icon?.width) * 2"
                :first-screen="false"
                :style="itemIconStyle(item.homeBadge)"
              />
              <!-- 角标通常为折扣，折扣镜像时，bff已处理为 %35-，所以默认角标文案不旋转，保证 %35- 显示，特殊需旋转的可以端处理配置textRotate -->
              <span
                class="product-bff__badgetext_content"
                :class="{
                  'product-bff__badgetext_rotate':
                    constantData?.GB_cssRight && item.homeBadge?.textRotate,
                }"
              >
                {{ item.homeBadge?.text }}
              </span>
            </div>
            <BaseImg
              v-if="item.homeBadge?.bgImage?.src"
              fit="cover"
              :placeholder="{
                width: item.homeBadge?.bgImage?.width,
                height: item.homeBadge?.bgImage?.height,
              }"
              :img-src="webpCheck(item.homeBadge?.bgImage?.src)"
              class="not-fsp-element product-bff__badgebg"
              :img-design-width="Number(item.homeBadge?.bgImage?.width) * 2"
              :first-screen="false"
            />
          </div>
          <!-- 普通的折扣角标 -->
          <div
            v-else-if="config.showNormalDiscountLabel"
            class="product-bff__normalbadge"
          >
            <div
              v-if="Number(item?.unit_discount) > 0"
              class="product-bff__normalbadgetext"
            >
            <span
              class="product-bff__normalbadgetext_content"
              :class="{
                'product-bff__normalbadgetext_rotate': GB_cssRight || cssRight,
              }"
            >
              {{ `${constantData.appLanguage === 'he' || constantData.appLanguage === 'il' ? '' : '-'}${item?.unit_discount}` }}<span style="display: inline-block">%</span>
            </span>
            </div>
          </div>
          <div
            v-else-if="config.showFlashSaleLabelInMainImg"
            class="product-bff__normalbadge"
          >
            <div
              v-if="Number(item?.unit_discount) > 0"
              class="product-bff__normalbadgetext flash-sale"
            >
              <i class="suiiconfont sui_icon_flashsale_15px"></i>
              <span
                class="product-bff__normalbadgetext_content"
                :class="{
                  'product-bff__normalbadgetext_rotate': GB_cssRight || cssRight,
                }"
              >
              {{ `${constantData.appLanguage === 'he' || constantData.appLanguage === 'il' ? '' : '-'}${item?.unit_discount}` }}<span style="display: inline-block">%</span>
              </span>
            </div>
          </div>
          <template v-else>
            <slot name="imgTopLeft"></slot>
          </template>
          <div
            v-if="showViewMore"
            class="product-bff__viewmore"
          >
            <div
              class="product-bff__viewmore_content"
              @click.stop="e => clickItem(e, { from: 'viewMore', emitsFun: 'viewMore' })"
            >
              <div class="product-bff__viewmore_icon">
                <img
                  :src="viewMoreSvg"
                  alt="view more"
                />
              </div>
              <div class="product-bff__viewmore_txt">
                {{ viewMoreText }}
              </div>
            </div>
          </div>
        </ClientOnly>
      </div>
      <!-- 主图下方区域 -->
      <div class="top-wrapper__bottom">
        <!-- 主图下方中位区域 -->
        <div class="top-wrapper__bottom-middle">
          <ClientOnly>
            <div
              v-if="item.homeBelt?.text && !config.hideHomeBelt"
              class="product-bff__belt"
            >
              <div
                class="product-bff__belttext"
                :style="beltTextStyle(item.homeBelt)"
              >
                <div class="product-bff__belttex_relative">
                  <div class="product-bff__belttex_absolute">
                    <BaseImg
                      v-if="item.homeBelt?.icon?.src"
                      fit="cover"
                      :img-src="webpCheck(item.homeBelt?.icon?.src)"
                      :placeholder="{
                        width: item.homeBelt?.icon?.width,
                        height: item.homeBelt?.icon?.height,
                      }"
                      class="not-fsp-element"
                      :class="{
                        'product-bff__belticon_rotate': item.homeBelt?.icon?.isArReverseIcon,
                      }"
                      :img-design-width="Number(item.homeBelt?.icon?.width) * 2"
                      :first-screen="false"
                      :style="itemIconStyle(item.homeBelt)"
                    />
                    <span class="product-bff__belttext_content">
                      {{ item.homeBelt?.text }}
                    </span>
                  </div>
                </div>
              </div>
              <BaseImg
                v-if="item.homeBelt?.bgImage?.src"
                fit="contain"
                :img-src="webpCheck(item.homeBelt?.bgImage?.src)"
                :placeholder="{
                  width: item.homeBelt?.bgImage?.width,
                  height: item.homeBelt?.bgImage?.height,
                }"
                class="not-fsp-element product-bff__beltbg"
                :img-design-width="Number(item.homeBelt?.bgImage?.width) * 2"
                :first-screen="false"
              />
            </div>
            <template v-else>
              <slot name="imgBottom"></slot>
            </template>
          </ClientOnly>
        </div>
      </div>
    </div>
    <div class="product-card__bottom-wrapper">
      <HomeBffPrice
        v-if="!config.hideAllBottomPrices"
        :goods-info="item"
        :config="config"
        :suggested-sale-type="suggestedSaleType"
        :price-font-size="priceFontSize"
      />
    </div>
  </div>
</template>

<script name="HomeEnhance" setup>
import {
  ref,
  computed,
  toRefs,
  provide,
  defineEmits,
  inject
} from 'vue'

// utils
import { columnToWidth } from 'public/src/pages/components/product/item_v3/js/constant'
import { transfromAndCutImg } from 'public/src/services/resource'
import { getProductDetailUrl, getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { getRealShowImg } from 'public/src/pages/components/product/item_v3/js/utils'

// components
import { ProductCardImg } from 'public/src/pages/components/product/item_v3/components/ProductCardImg'
import { ClientOnly } from '@sheinfe/vue-client-only'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import HomeBffPrice from './HomeBffPrice.vue'
import { commonProps } from './product-props.js'

// inject
const metricsConfig = inject('metricsConfig', {})

 // props
const props = defineProps({
  ...commonProps,
})

// data
const {
  constantData,
  item,
  index,
  config,
  context
} = toRefs(props)
const productCardRef = ref(null)
const columnToWidthHome = ref(columnToWidth)
const mainImg = ref('') // 主图
const imgRatio = ref('') // mainImg 图片宽高比
const viewMoreSvg = constantData?.value?.GB_cssRight
  ? `https://shein.ltwebstatic.com/svgicons/2024/07/11/17206852561468256043.svg`
  : `https://shein.ltwebstatic.com/svgicons/2024/07/11/17206852562878525324.svg`

// setup/created
// 主图
mainImg.value = item?.value?.goods_img || ''

// emits
const emits = defineEmits(['clickItem', 'viewMore'])

// methods
function onImgLoadFinish(imgLoadType, e) {
  if (imgRatio.value) {
    return
  }
  if (imgLoadType == 'succ') {
    const src = e.target.src
    getImageRatio(src).then(ratio => {
      imgRatio.value = ratio
    })
  }
}

async function handleGoodsUrl(params, ignoreImgRatio = false) {
  const {
    goods_url_name = 'product',
    goods_id,
    cat_id,
    mall_code,
    sku_code,
  } = params
  const transformSrc = getRealShowImg({
    item: item?.value,
    lazyLoadSrc: getLazyloadSrc(),
  }).imgSrc

  const productDetailUrl = await getProductDetailUrl({
    imgSrc: transformSrc,
    goods_url_name,
    ignoreImgRatio,
    goods_id,
    cat_id,
    mall_code,
    urlQuery: {
      skucode: sku_code,
      ...config?.value?.urlQuery,
    },
    langPath: constantData?.value?.langPath,
  })

  return productDetailUrl
}

async function clickItem(e, { from, emitsFun } = {}) {
  // 跳转商详
  let url = await handleGoodsUrl({ ...item?.value }, true)
  let imgRatioValue = imgRatio.value
  if (!imgRatioValue) {
    imgRatioValue = await getMainImgRatio()
  }
  url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}imgRatio=${imgRatioValue}`
  // 点击商品项，透传事件
  emits(emitsFun, {
    ...item?.value,
    imgRatio: imgRatioValue,
    goodsUrl: url,
    index: index.value,
    target: productCardRef.value,
    scrollPos: $(window).scrollTop(), // 记录当前点击商品项的scrollTop
    clickPosition: from ?? '',
  })
}

function getLazyloadSrc() {
  const { LAZY_IMG_SOLID_COLOR = '' } = constantData?.value || {}
  let lazyLoadSrc = ''
  try {
    const imgElement = productCardRef?.value?.querySelector(
      '.product-card__main-img',
    )
    lazyLoadSrc = imgElement.getAttribute('src') || ''
    if (lazyLoadSrc === LAZY_IMG_SOLID_COLOR) {
      return ''
    }
  } catch (err) {
    return ''
  }
  return lazyLoadSrc
}

async function getMainImgRatio() {
  const imgSrc = getRealShowImg({
    item: item?.value,
    lazyLoadSrc: getLazyloadSrc(),
  }).imgSrc
  return (imgRatio.value = await getImageRatio(imgSrc))
}

function cutImg(imgUrl, forceTransform = false) {
  const {
    deviceData = '',
    isSupportWeb = '',
    sceneMap = {},
    isSupprotCut = '',
  } = context?.value?.RESOURCE_SDK || {}
  const cutData = {
    deviceData,
    isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
    isSupprotCut: forceTransform ? true : Boolean(isSupprotCut),
    imgUrl,
    designWidth: 950,
    sceneMap,
    exp: 'unCut=0#max=950',
  }
  return transfromAndCutImg(cutData)
}

function checkSizeUnit(size) {
  return /(px|em|rem|vh|vw|vmin|vmax|%)$/.test(size)
}

function badgeTextStyle(badge) {
  let fontSize = '0.24rem'
  if (badge.textSize && !checkSizeUnit(badge.textSize)) {
    fontSize = badge.textSize / 37.5 + 'rem'
  }
  return {
    fontSize,
    fontWeight: Number(badge?.isInBold) ? '700' : '400',
    color: badge.textColor || '#000',
  }
}

function itemIconStyle(iconObj) {
  let width = '0.24rem'
  let height = '0.24rem'
  if (iconObj.icon?.width) {
    if (iconObj.icon?.width && !checkSizeUnit(iconObj.icon?.width)) {
      width = iconObj.icon.width / 37.5 / 3 + 'rem' // 缩小三倍
    }
  } else {
    if (iconObj.textSize && !checkSizeUnit(iconObj.textSize)) {
      width = iconObj.textSize / 37.5 / 3 + 'rem' // 缩小三倍
    }
  }
  if (iconObj.icon?.height) {
    if (iconObj.icon?.height && !checkSizeUnit(iconObj.icon?.height)) {
      height = iconObj.icon.height / 37.5 / 3 + 'rem' // 缩小三倍
    }
  } else {
    if (iconObj.textSize && !checkSizeUnit(iconObj.textSize)) {
      height = iconObj.textSize / 37.5 / 3 + 'rem' // 缩小三倍
    }
  }
  return {
    width,
    height,
  }
}

function borderStyle(src) {
  return {
    backgroundImage: `url(${cutImg(src, true)})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
  }
}

function beltTextStyle(belt) {
  let fontSize = '0.24rem'
  if (belt.textSize && !checkSizeUnit(belt.textSize)) {
    fontSize = belt.textSize / 37.5 + 'rem'
  }
  return {
    fontSize,
    fontWeight: belt.textWeight || '400',
    height: belt.textHeight || '100%',
    color: belt.textColor || '#000',
  }
}

function webpCheck(src) {
  return src?.replace('.webp', '.png')
}

// provides，主图
provide('constantData', constantData.value)
provide('item', item.value)
provide('config', config.value)
provide('index', index.value)
</script>

<style lang="less" scoped>
.simple-row-item.product-bff {
  width: 100%;
  background-color: transparent; // 背景透明，避免白色造成四角未到圆角
  position: relative;
  // 主图区域
  .product-card__top-wrapper {
    position: relative;
    overflow: hidden;

    border-radius: var(--product-card-img-radius, 0);

    .top-wrapper {
      // &__main-img {
      //   overflow: hidden;
      // }
      // 主图下方区域
      &__bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      // 主图下方中位区域上
      &__bottom-middletop {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
      // 主图右上区域
      &__top-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  // 主要信息
  .product-card__bottom-wrapper {
    position: relative;
    padding: var(--product-card__bottom-wrapper, 0.106rem 0 0 0); // 实时反馈用

    &.transparent {
      opacity: 0;
    }

    &.dynamic-price .content__sale-price-wrapper {
      height: 0.5333rem;
      line-height: 0.5333rem;
    }

    &_soldout > div,
    &_soldout > section {
      opacity: 0.3;
    }
    .product-card__camel-case-price__number-before-dot {
      font-size: 0.3733rem;
    }
  }
  .product-card__bottom-wrapper {
    padding: 0 0 2px;
  }
  .product-card__top-wrapper {
    border-radius: 0.055rem;
  }
  .crop-image-container .product-card__main-img {
    border-radius: 0.055rem;
  }
  .top-wrapper__top-left {
    width: 100%;
  }
  .content__sale-price-wrapper,
  .prices-info__sale-price {
    max-width: 100%;
  }
  .prices-info__sale-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
.product-bff {
  overflow: hidden;
  &__badge {
    min-width: 0.75rem;
    max-width: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }
  &__badge,
  &__badge .base-img__inner {
    border-bottom-right-radius: 0.055rem;
  }
  &__badgetext {
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  &__badgetext_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    // 镜像方向保持一致，避免 %23- 展示为 -%23
    direction: ltr /* rtl: ltr */;
  }
  &__badgetext_rotate {
    direction: ltr;
  }
  // 普通折扣角标
  &__normalbadge {
    min-width: 0.75rem;
    max-width: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }
  &__normalbadge,
  &__normalbadge .base-img__inner {
    border-bottom-right-radius: 0.055rem;
  }
  &__normalbadgetext {
    font-size: 0.24rem;
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    color: #fff;
    background-color: rgb(250, 99, 56);
    &.flash-sale {
      background: #facf19;
      color: #222;
      flex-direction: column;
      i {
        display: inline-block;
        height: 15px;
      }
    }
  }
  &__normalbadgetext_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    // 镜像方向保持一致，避免 %23- 展示为 -%23
    direction: ltr /* rtl: ltr */;
  }
  &__normalbadgetext_rotate {
    direction: ltr;
  }
  &__badgeicon_rotate {
    transform: scaleX(-1);
  }
  &__badgebg.base-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &__viewmore {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: @zindex-hack + 1;
  }
  &__viewmore_content {
    display: list-item;
    list-style: none;
    text-align: center;
    width: 100%;
  }
  &__viewmore_icon {
    display: inline-block;
    margin-bottom: 0.2133rem;
  }
  &__viewmore_txt {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  &__border {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.055rem;
  }
  &__belt {
    width: 100%;
    overflow: hidden;
    z-index: 2;
  }
  &__belt,
  &__belt .base-img__inner {
    border-bottom-left-radius: 0.055rem;
    border-bottom-right-radius: 0.055rem;
  }
  &__beltbg {
    position: relative;
    z-index: 1;
  }
  &__belttext {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
  &__belttex_relative {
    // 控制腰带高度
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__belttex_absolute {
    // 控制腰带居中
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 2px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  &__belticon_rotate {
    transform: scaleX(-1);
  }
  &__belttext_content {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__view_more {
    .product-card__bottom_wrapper {
      opacity: 0.4;
    }
  }
  &__view_more,
  &__border_out {
    .top-wrapper__top-left {
      height: 100%;
    }
  }
}
</style>
