<script lang="jsx">
import { defineComponent } from 'vue'
import Enhance from './Enhance.vue'
import HomeEnhance from './HomeEnhance.vue'
import { commonProps } from './product-props.js'

export default defineComponent({
  name: 'ProductItemSimpleEntry',
  components: {
    Enhance,
    HomeEnhance
  },
  inheritAttrs: false,
  props: {
    ...commonProps,
  },
  render({ $props: props, $attrs: attrs, $slots: slots }) {
    const enhanceCtx = {
      ...props,
      ...attrs,
    }
    const HomeProductCard = this.context?.abtInfoObj?.HomeProductCard == '1'
    if (HomeProductCard) { // 首页商卡
      return <HomeEnhance v-slots={slots} {...enhanceCtx} />
    }
    return <Enhance v-slots={slots} {...enhanceCtx} />
  },
})
</script>
